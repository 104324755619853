import React from 'react'
import './Home.css'
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useEffect, useRef, useState } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css'; 
import { Link } from 'react-scroll';


function Home() {
  const [name, setName] = useState("");
  const [number, setNumber] = useState("");
  const [email, setEmail] = useState("");
  const [services, setServices] = useState(""); // Renamed to services

  const handleSend = () => {
    const message = `Hello, my name is ${name}. My number is ${number}, and my email is ${email}. I am interested in the following service: ${services}.`;
    const whatsappUrl = `https://wa.me/971523906635?text=${encodeURIComponent(message)}`;
    
    // Redirect to WhatsApp with the pre-filled message
    window.open(whatsappUrl, "_blank");
  };

  useEffect(() => {
    AOS.init(); // Initialize AOS
  }, []);

  const carousel = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1.8,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1000,
  };

  const clients = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4.8,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3.5,
          slidesToScroll: 1,
          centerMode: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2.5,
          slidesToScroll: 1,
          centerMode: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1.5,
          slidesToScroll: 1,
          centerMode: true,
        },
      },
    ],
  };

  const service = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1500,
    centerMode: true,
    centerPadding: "0px",
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          centerMode: true,
        },
      },
    ],
  };
  const [isVisible, setIsVisible] = useState(false);
  const countRef = useRef();
  const [showNavbar, setShowNavbar] = React.useState(false);

  const handleShowNavbar = () => {
    setShowNavbar(!showNavbar);
    console.log(showNavbar)
  };

  const counters = [
    { endValue: 34, label: 'Expert Workers' },
    { endValue: 3, label: 'Outlets' },
    { endValue: 10, label: 'Years of Experience' },
  ];

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.unobserve(countRef.current);
        }
      },
      { threshold: 0.5 } 
    );

    observer.observe(countRef.current);

    return () => observer.disconnect();
  }, []);

  const CountUp = ({ endValue }) => {
    const [count, setCount] = useState(0);
    

    useEffect(() => {
      if (isVisible) {
        let start = 0;
        const duration = 2000; 
        const stepTime = Math.abs(Math.floor(duration / endValue));

        const timer = setInterval(() => {
          start += 1;
          setCount(start);
          if (start === endValue) clearInterval(timer);
        }, stepTime);

        return () => clearInterval(timer);
      }
    }, [isVisible, endValue]);

    return <h1>{count}</h1>;
  };

  return (
    <div>
      <div className=''>
      <div className='header-section'>
      <div className='row'>
      <div className='col-12 col-sm-12 col-md-8 col-lg-8 col-xl-8'>
      <nav className="navbar">
      <div className="container">
        <div className="logo">
          {/* Logo can be placed here */}
          <img src='/images/footer/LOGO 1.png'></img>
        </div>
        <div className="menu-icon" onClick={handleShowNavbar}>
        <i className="bi bi-list" style={{ fontSize: '24px', color: 'white' }}></i>

        </div>
        <div className={`nav-elements ${showNavbar ? "active" : ""}`}>
          <ul>
            <li><Link to='home'  onClick={handleShowNavbar}>Home</Link></li>
            <li ><Link to='services'  onClick={handleShowNavbar}>Service</Link></li>
            <li><Link to='about'  onClick={handleShowNavbar}>About us</Link></li>
            <li><Link to='contact'  onClick={handleShowNavbar}>Contact us</Link></li>
            {/* <li></li> */}
          </ul>
        </div>
      </div>
    </nav>
      </div>
      <div className='col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4'>
        <div className='nav-right'>

        </div>
      </div>
  </div>
    </div>
        <section className='section-one' id='home' >
          <div className='row'>
            <div className='col-12 col-xs-12 col-sm-12 col-md-8 col-lg-8 col-xl-8' >
              <div className='home-content'>
                <h1 data-aos="zoom-in" data-aos-duration="1000">
                  <span className='main'>Your Printing</span>
                  <br></br>
                  <span className='sub'>Solutions in <br></br>
                    UAE !!!</span>
                </h1>
                <p data-aos="fade-right" data-aos-duration="1000">Discover Printzed, the complete solution for all your designing and
                  printing needs. Whether you're working on a small project or managing </p>

                <h2 className='yellow-line' data-aos="slide-up" data-aos-duration="1000"></h2>

                <div className='buttons'>
                  <div className='row'>
                    <div className='col-6' data-aos="flip-left" data-aos-duration="1000">
                      <button className='button-getquote'>Get Quote</button>
                    </div>
                    <div className='col-6' data-aos="flip-right" data-aos-duration="1000">
                      <button className='button-aboutus'>About us</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-12 col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4'>
              <div className='home-image'>
                <img src='images/firstpage/Group 51.png' data-aos="zoom-in" data-aos-duration="1000"></img>
              </div>
            </div>
          </div>
        </section>


        <section className='section-two'>
          <div className='work-flow'>
            <div className='heading' data-aos="fade-up" data-aos-duration="1000">
              <h1>Our Work Flow</h1>
            </div>
            <div className='sub-text' data-aos="fade-up" data-aos-duration="1200">
              <p>
                At Printzed Design & Artwork Solution, we bring together creativity and precision. Leading the industry since
                2015, we are known for our innovative design, top-notch quality, and dependable service across the globe.
                With Creative Excellence, our skilled team of designers and printing experts combine creativity and knowledge
                to deliver exceptional results for every project, guaranteeing that each piece we create is a work of art.
              </p>
            </div>
            <div className='work-chart'>
              <img className='background-image' src='/images/workflow/VectorLine.png' alt="workflow-background" />
              <div className='row'>
                <div className='col-6 col-xs-6 col-sm-6 col-md-3 col-lg-3 col-xl-3'>
                  <div className='box-gif' data-aos="fade-right" data-aos-duration="1000">
                    <img src='/images/workflow/system-regular-14-article-hover-article.gif' alt="Collect Details" />
                    <br /><br />
                    <span>Collect Details</span>
                  </div>
                </div>
                <div className='col-6 col-xs-6 col-sm-6 col-md-3 col-lg-3 col-xl-3'>
                  <div className='box-gif' data-aos="fade-up" data-aos-duration="1000">
                    <img src='/images/workflow/wired-outline-35-edit-hover-circle.gif' alt="Design" />
                    <br /><br />
                    <span>Design</span>
                  </div>
                </div>
                <div className='col-6 col-xs-6 col-sm-6 col-md-3 col-lg-3 col-xl-3'>
                  <div className='box-gif' data-aos="fade-left" data-aos-duration="1000">
                    <img src='/images/workflow/approve.gif' alt="Approve & Print" />
                    <br /><br />
                    <span>Approve & Print</span>
                  </div>
                </div>
                <div className='col-6 col-xs-6 col-sm-6 col-md-3 col-lg-3 col-xl-3'>
                  <div className='box-gif' data-aos="fade-left" data-aos-duration="1000">
                    <img src='/images/workflow/wired-outline-497-truck-delivery-hover-pinch.gif' alt="Fast Delivery" />
                    <br /><br />
                    <span>Fast Delivery</span>
                  </div>
                </div>
              </div>
            </div>
            <img className='pillow' src='/images/workflow/pillow.png'  />
            <img className='paper' src='/images/workflow/cate-2 1.png'  />
          </div>
        </section>


        <section className='section-three'>
          <div className='row'>
            <div className='col-12 col-xs-12 col-sm-12 col-md-7 col-lg-8 col-xl-8'>
              <div className='track-content'>
                <div className='heading' data-aos="fade-up" data-aos-duration="1000">
                  <h1>Track Your Work Progress
                    Instantly &nbsp;
                    <span className='special-heading'>with Printzed!</span>
                  </h1>
                </div>
                <div className='sub-heading' data-aos="fade-up" data-aos-duration="1200">
                  Discover Printzed, the complete solution for all your designing and
                  printing needs. Whether you're working on a small project or managing
                  large-scale operations, Printzed offers seamless tracking to keep you on
                  top of your progress. Effortlessly monitor your tasks, streamline your
                  workflow, and achieve your goals with precision.
                </div>
                <h2 className='yellow-line' data-aos="slide-up" data-aos-duration="1000"></h2>
                <div className='track-item'>
                  <div className='row'>
                    <div className='col-6 col-sm-6 col-md-4 col-lg-4 col-xl-4' data-aos="fade-right" data-aos-duration="1000">
                      <label className='mb-2'>Enter your <span className='special'>tracking id</span></label>
                      <br />
                      <input type='text' placeholder='Enter Tracking Id' data-aos="zoom-in" data-aos-duration="1000" />
                    </div>

                    <div className='col-5 col-sm-5 col-md-3 col-lg-3 col-xl-3' data-aos="fade-up" data-aos-duration="1000">
                      <button data-bs-toggle="modal" data-bs-target="#exampleModal" >Track</button>
                    </div>
                    <div className='col-1 col-sm-1 col-md-5 col-lg-5 col-xl-5'></div>
                  </div>
                </div>
                <h2 className='yellow-line' data-aos="slide-up" data-aos-duration="1000"></h2>
                <img className="available-on" src='/images/Track/AVAILABE ICON.png'  alt="Available on" />
                <img className='hand-image' src='/images/Track/hand-showing-smartphone-with-bla (1).png1 1.png'  alt="Hand with smartphone" />
              </div>
            </div>
            <div className='col-12 col-xs-12 col-sm-12 col-md-5 col-lg-4 col-xl-4'>
              <div className='track-image'>
                <img src="/images/Track/FAST DELIVERY.png" data-aos="fade-up" data-aos-duration="1000" alt="Fast Delivery" />
              </div>
            </div>
          </div>
        </section>


        <section className='package'>
          <div className='heading'>
           <h4>OUR PACKAGES</h4>
          </div>
          <div className=''>
          <ul class="nav nav-tabs" id="myTab" role="tablist">
  <li class="nav-item" role="presentation">
    <button class="nav-link active" id="buisness-tab" data-bs-toggle="tab" data-bs-target="#buisness" type="button" role="tab" aria-controls="buisness" aria-selected="true">Buisness Website</button>
  </li>
  <li class="nav-item" role="presentation">
    <button class="nav-link" id="ecommerce-tab" data-bs-toggle="tab" data-bs-target="#ecommerce" type="button" role="tab" aria-controls="ecommerce" aria-selected="false">ECommerce Website</button>
  </li>
  <li class="nav-item" role="presentation">
    <button class="nav-link" id="graphics-tab" data-bs-toggle="tab" data-bs-target="#graphics" type="button" role="tab" aria-controls="graphics" aria-selected="false">Grpahic Design</button>
  </li>
  <li class="nav-item" role="presentation">
    <button class="nav-link" id="digital-tab" data-bs-toggle="tab" data-bs-target="#digital" type="button" role="tab" aria-controls="digital" aria-selected="false">Digital Marketing</button>
  </li>
</ul>
<div class="tab-content" id="myTabContent">
  <div class="tab-pane fade show active" id="buisness" role="tabpanel" aria-labelledby="buisness-tab">
    <div >
      <div className='row'>
      <div className='col-xs-6 col-sm-6 col-md-4 col-lg-4 col-xl-4'>
      <div className="package-item">
      <div className='package-head'>
        <h6>BASIC PACKAGE</h6>
        <span>Suitable for Potential Super-startup and Brand revamps for companies</span>
      </div>
      <div className='package-details'>
          <p className='price'><sup>AED</sup><span>669</span></p>
          <ul>
            <li><i class="bi bi-arrow-right-circle"></i><h6> 5 stock image</h6></li>
            <li><i class="bi bi-arrow-right-circle"></i><h6> 5 Pages Website</h6></li>
            <li><i class="bi bi-arrow-right-circle"></i> <h6>contact and Query Form</h6></li>
            <li><i class="bi bi-arrow-right-circle"></i> <h6>48 to 72 hours TAT</h6></li>
            <li><i class="bi bi-arrow-right-circle"></i> <h6>Complete Deployment</h6></li>
            <li><i class="bi bi-arrow-right-circle"></i> <h6>Additional Charges and Domain Hosting</h6></li>
          </ul>
      </div>
    </div>
      </div>
      <div className='col-xs-6 col-sm-6 col-md-4 col-lg-4 col-xl-4'>
      <div className="package-item">
      <div className='package-head'>
        <h6>STARTUP PACKAGE</h6>
        <span>Suitable for Potential Super-startup and Brand revamps for companies</span>
      </div>
      <div className='package-details'>
          <p className='price'><sup>AED</sup><span>999</span></p>
          <ul>
            <li><i class="bi bi-arrow-right-circle"></i><h6> 10 stock image</h6></li>
            <li><i class="bi bi-arrow-right-circle"></i><h6> Upto 10 Pages Website</h6></li>
            <li><i class="bi bi-arrow-right-circle"></i> <h6>3 Banner Design</h6></li>
            <li><i class="bi bi-arrow-right-circle"></i> <h6>48 to 72 hours TAT</h6></li>
            <li><i class="bi bi-arrow-right-circle"></i> <h6>Additional $100 for mobile UI/UX</h6></li>
            <li><i class="bi bi-arrow-right-circle"></i> <h6>Hosting Includes Upto 12 Months</h6></li>
          </ul>
      </div>
    </div>
      </div>
      <div className='col-xs-6 col-sm-6 col-md-4 col-lg-4 col-xl-4'>
      <div className="package-item">
      <div className='package-head'>
        <h6>PRO PACKAGE</h6>
        <span>Suitable for Potential Super-startup and Brand revamps for companies</span>
      </div>
      <div className='package-details'>
          <p className='price'><sup>AED</sup><span>1299</span></p>
          <ul>
            <li><i class="bi bi-arrow-right-circle"></i><h6> 20+ stock image</h6></li>
            <li><i class="bi bi-arrow-right-circle"></i><h6> 10 to 12 Unique Pages Website</h6></li>
            <li><i class="bi bi-arrow-right-circle"></i> <h6>CMS Admin Panel Support</h6></li>
            <li><i class="bi bi-arrow-right-circle"></i> <h6>72 to 96 hours TAT</h6></li>
            <li><i class="bi bi-arrow-right-circle"></i> <h6>100% Satisfaction Guarantee</h6></li>
            <li><i class="bi bi-arrow-right-circle"></i> <h6>Hosting Includes Upto 12 Months</h6></li>
          </ul>
      </div>
    </div>
      </div>
      </div>
    </div>
  </div>
  <div class="tab-pane fade" id="ecommerce" role="tabpanel" aria-labelledby="ecommerce-tab">
  <div >
      <div className='row'>
      <div className='col-xs-6 col-sm-6 col-md-4 col-lg-4 col-xl-4'>
      <div className="package-item">
      <div className='package-head'>
        <h6>STARTUP PACKAGE</h6>
        <span>Suitable for Potential Super-startup and Brand revamps for companies</span>
      </div>
      <div className='package-details'>
          <p className='price'><sup>AED</sup><span>1499</span></p>
          <ul>
            <li><i class="bi bi-arrow-right-circle"></i><h6>Custom Design</h6></li>
            <li><i class="bi bi-arrow-right-circle"></i><h6>upto 50 Products</h6></li>
            <li><i class="bi bi-arrow-right-circle"></i> <h6>Content Managment System</h6></li>
            <li><i class="bi bi-arrow-right-circle"></i> <h6>Mini Shop Cart Integration</h6></li>
            <li><i class="bi bi-arrow-right-circle"></i> <h6>Payment Module Integration</h6></li>
            <li><i class="bi bi-arrow-right-circle"></i> <h6>100% Satisfaction Guarantee</h6></li>
            <li><i class="bi bi-arrow-right-circle"></i> <h6>100% Unique Design Guarantee</h6></li>
            <li><i class="bi bi-arrow-right-circle"></i> <h6>100% Money Back Guarantee</h6></li>
            <li><i class="bi bi-arrow-right-circle"></i> <h6>Free Final Files (Ai,PSD,Html,jpeg,webp in fromats for all web use)</h6></li>
          </ul>
      </div>
    </div>
      </div>
      <div className='col-xs-6 col-sm-6 col-md-4 col-lg-4 col-xl-4'>
      <div className="package-item">
      <div className='package-head'>
        <h6>PROFESIONAL PACKAGE</h6>
        <span>Suitable for Potential Super-startup and Brand revamps for companies</span>
      </div>
      <div className='package-details'>
          <p className='price'><sup>AED</sup><span>1999</span></p>
          <ul>
            <li><i class="bi bi-arrow-right-circle"></i><h6>Custom Design</h6></li>
            <li><i class="bi bi-arrow-right-circle"></i><h6>upto 100 Products</h6></li>
            <li><i class="bi bi-arrow-right-circle"></i> <h6>Content Managment System</h6></li>
            <li><i class="bi bi-arrow-right-circle"></i> <h6>Mini Shop Cart Integration</h6></li>
            <li><i class="bi bi-arrow-right-circle"></i> <h6>Payment Module Integration</h6></li>
            <li><i class="bi bi-arrow-right-circle"></i> <h6>100% Satisfaction Guarantee</h6></li>
            <li><i class="bi bi-arrow-right-circle"></i> <h6>100% Unique Design Guarantee</h6></li>
            <li><i class="bi bi-arrow-right-circle"></i> <h6>100% Money Back Guarantee</h6></li>
            <li><i class="bi bi-arrow-right-circle"></i> <h6>Free Final Files (Ai,PSD,Html,jpeg,webp in fromats for all web use)</h6></li>
          </ul>
      </div>
    </div>
      </div>
      <div className='col-xs-6 col-sm-6 col-md-4 col-lg-4 col-xl-4'>
      <div className="package-item">
      <div className='package-head'>
        <h6>ELITE PACKAGE</h6>
        <span>Suitable for Potential Super-startup and Brand revamps for companies</span>
      </div>
      <div className='package-details'>
          <p className='price'><sup>AED</sup><span>1499</span></p>
          <ul>
            <li><i class="bi bi-arrow-right-circle"></i><h6>Custom Design</h6></li>
            <li><i class="bi bi-arrow-right-circle"></i><h6>Unlimited Products</h6></li>
            <li><i class="bi bi-arrow-right-circle"></i> <h6>Content Managment System</h6></li>
            <li><i class="bi bi-arrow-right-circle"></i> <h6>Mini Shop Cart Integration</h6></li>
            <li><i class="bi bi-arrow-right-circle"></i> <h6>Payment Module Integration</h6></li>
            <li><i class="bi bi-arrow-right-circle"></i> <h6>100% Satisfaction Guarantee</h6></li>
            <li><i class="bi bi-arrow-right-circle"></i> <h6>100% Unique Design Guarantee</h6></li>
            <li><i class="bi bi-arrow-right-circle"></i> <h6>100% Money Back Guarantee</h6></li>
            <li><i class="bi bi-arrow-right-circle"></i> <h6>Free Final Files (Ai,PSD,Html,jpeg,webp in fromats for all web use)</h6></li>
          </ul>
      </div>
    </div>
      </div>
      </div>
    </div>
  </div>
  <div class="tab-pane fade" id="graphics" role="tabpanel" aria-labelledby="graphics-tab">
  <div >
      <div className='row'>
      <div className='col-xs-6 col-sm-6 col-md-4 col-lg-4 col-xl-4'>
      <div className="package-item">
      <div className='package-head'>
        <h6>BASIC PACKAGE</h6>
        <span>Suitable for Potential Super-startup and Brand revamps for companies</span>
      </div>
      <div className='package-details'>
          <p className='price'><sup>AED</sup><span>499</span></p>
          <ul>
            <li><i class="bi bi-arrow-right-circle"></i><h6>1 Logo Design</h6></li>
            <li><i class="bi bi-arrow-right-circle"></i><h6>Comaony Profile upto 5 Page</h6></li>
            <li><i class="bi bi-arrow-right-circle"></i> <h6>1 Banner Design</h6></li>
            <li><i class="bi bi-arrow-right-circle"></i> <h6>1 Broucher Design</h6></li>
            <li><i class="bi bi-arrow-right-circle"></i> <h6>48 to 72 hours TAT</h6></li>
            <li><i class="bi bi-arrow-right-circle"></i> <h6>100% Satisfaction Guarantee</h6></li>
          </ul>
      </div>
    </div>
      </div>

      </div>
    </div>
  </div>
  <div class="tab-pane fade" id="digital" role="tabpanel" aria-labelledby="digital-tab">
  <div >
      <div className='row'>
      <div className='col-xs-6 col-sm-6 col-md-4 col-lg-4 col-xl-4'>
      <div className="package-item">
      <div className='package-head'>
        <h6>STARTUP PACKAGE</h6>
        <span>Suitable for Potential Super-startup and Brand revamps for companies</span>
      </div>
      <div className='package-details'>
          <p className='price'><sup>AED</sup><span>699</span></p>
          <ul>
            <li><i class="bi bi-arrow-right-circle"></i><h6>4 Post in Week on Social Media</h6></li>
            <li><i class="bi bi-arrow-right-circle"></i><h6>Running Paid Campaigns</h6></li>
            <li><i class="bi bi-arrow-right-circle"></i> <h6>Post Design and Content Creation</h6></li>
            <li><i class="bi bi-arrow-right-circle"></i> <h6>Dedicated Account Manager</h6></li>
            <li><i class="bi bi-arrow-right-circle"></i> <h6>100% Satisfaction Guarantee</h6></li>
            <li><i class="bi bi-arrow-right-circle"></i> <h6>Website Technical Support</h6></li>
          </ul>
      </div>
    </div>
      </div>

      </div>
    </div>
  </div>
</div>
          </div>
        </section>


        <section className='section-four'>
          <div className='carousel-one'>
            <Slider {...carousel}>
              <div><img src="/images/Banners/LH.png" alt="Slide 1" /></div>
              <div><img src="/images/Banners/SPORTS JERSEY.png" alt="Slide 2" /></div>
            </Slider>

          </div>
        </section>

        <section className='section-five' id='services'>
          <div className='our-service'>
            <div className='heading' data-aos="fade-up"  data-aos-duration="1000">
              <h4><span>Our</span> Services</h4>
            </div>

            <div className='service-content service-blocks'>
              <div className='row'>
                <div className='col-md-6 col-lg-3 col-xl-3' data-aos="fade-up" data-aos-delay="100"  data-aos-duration="1000">
                  <div className='items'>
                    <img src="/images/service/GRAPHIC DESIGNING.png" alt="Graphic Designing" />
                  </div>
                  <div className='title'>
                    <span> GRAPHIC DESIGNING</span>
                    <p>Explore the World of Graphics Designing</p>
                  </div>
                </div>
                <div className='col-md-6 col-lg-3 col-xl-3' data-aos="fade-up" data-aos-delay="200"  data-aos-duration="1000">
                  <div className='items'>
                    <img src='/images/service/OFFSETDIGITAL PRINTING1.png' alt="Offset/Digital Printing" />
                  </div>
                  <div className='title'>
                    <span> OFFSET/DIGITAL PRINTING</span>
                    <p>Choosing the Right Printing Method: Offset vs. Digital</p>
                  </div>
                </div>
                <div className='col-md-6 col-lg-3 col-xl-3' data-aos="fade-up" data-aos-delay="300"  data-aos-duration="1000">
                  <div className='items'>
                    <img src='/images/service/GIFT & PROMOTIONALS.png' alt="Gift & Promotionals" />
                  </div>
                  <div className='title'>
                    <span>GIFT & PROMOTIONALS</span>
                    <p>Elevate Your Brand: Gift and Promotional Solutions.</p>
                  </div>
                </div>
                <div className='col-md-6 col-lg-3 col-xl-3' data-aos="fade-up" data-aos-delay="400"   data-aos-duration="1000">
                  <div className='items'>
                    <img src='/images/service/FLAG & POSTERS.png' alt="Flag & Posters" />
                  </div>
                  <div className='title'>
                    <span> FLAG & POSTERS</span>
                    <p>Create an Impact: Flag and Poster Solutions.</p>
                  </div>
                </div>
                <div className='col-md-6 col-lg-3 col-xl-3' data-aos="fade-up" data-aos-delay="500"  data-aos-duration="1000">
                  <div className='items'>
                    <img src='/images/service/ROLL UP BANNERS.png' alt="Roll Up Banners" />
                  </div>
                  <div className='title'>
                    <span> ROLL UP BANNERS</span>
                    <p>Stand Tall: Roll-Up Banner Solutions</p>
                  </div>
                </div>
                <div className='col-md-6 col-lg-3 col-xl-3' data-aos="fade-up" data-aos-delay="600"  data-aos-duration="1000">
                  <div className='items'>
                    <img src='/images/service/GRAPHIC DESIGNING.png' alt="Wall Graphics" />
                  </div>
                  <div className='title'>
                    <span>WALL GRAPHICS</span>
                    <p>Revamp Your Spaces with Wall Graphics Solutions</p>
                  </div>
                </div>
                <div className='col-md-6 col-lg-3 col-xl-3' data-aos="fade-up" data-aos-delay="700"  data-aos-duration="1000">
                  <div className='items'>
                    <img src='/images/service/WINDOW GRAPHICS.png' alt="Window Graphics" />
                  </div>
                  <div className='title'>
                    <span> WINDOW GRAPHICS</span>
                    <p>Enhancing Windows with innovative Graphic Solutions.</p>
                  </div>
                </div>
                <div className='col-md-6 col-lg-3 col-xl-3' data-aos="fade-up" data-aos-delay="800"  data-aos-duration="1000">
                  <div className='items'>
                    <img src='/images/service/EXIBHITION GRAPHICS.png' alt="Exhibition Graphics" />
                  </div>
                  <div className='title'>
                    <span> EXHIBITION GRAPHICS</span>
                    <p>Showcase Success: Exhibition Graphics Solutions.</p>
                  </div>
                </div>
                <div className='col-md-6 col-lg-3 col-xl-3' data-aos="fade-up" data-aos-delay="900"  data-aos-duration="1000">
                  <div className='items'>
                    <img src='/images/service/3D SIGNAGE.png' alt="3D Signage" />
                  </div>
                  <div className='title'>
                    <span>3D SIGNAGE</span>
                    <p>Dimensional Impact: 3D Signage Solutions</p>
                  </div>
                </div>
                <div className='col-md-6 col-lg-3 col-xl-3' data-aos="fade-up" data-aos-delay="1000"  data-aos-duration="1000">
                  <div className='items'>
                    <img src='/images/service/HOARDING GRAPHICS.png' alt="Hoarding Graphics" />
                  </div>
                  <div className='title'>
                    <span> HOARDING GRAPHICS</span>
                    <p>Maximize Impact: Hoarding Graphics Solutions</p>
                  </div>
                </div>
                <div className='col-md-6 col-lg-3 col-xl-3' data-aos="fade-up" data-aos-delay="1100"  data-aos-duration="1000">
                  <div className='items'>
                    <img src='/images/service/VEHICLE BRANDING.png' alt="Vehicle Branding" />
                  </div>
                  <div className='title'>
                    <span> VEHICLE BRANDING</span>
                    <p>Flexible Signage Solutions: Adaptable Advertising.</p>
                  </div>
                </div>
                <div className='col-md-6 col-lg-3 col-xl-3' data-aos="fade-up" data-aos-delay="1200"  data-aos-duration="1000">
                  <div className='items'>
                    <img src='/images/service/FOOD TRUCK BRANDING.png' alt="Food Truck Branding" />
                  </div>
                  <div className='title'>
                    <span>FOOD TRUCK BRANDING</span>
                    <p>Drive-Thru Delight: Food Truck Branding Solutions.</p>
                  </div>
                </div>
              </div>
            </div>

            <div className='service-carousel' data-aos="zoom-in">
              <div className='service-content'>
                <Slider {...service}>
                  <div>
                    <div className='items'>
                      <img src="/images/service/GRAPHIC DESIGNING.png" alt="Graphic Designing" />
                    </div>
                    <div className='title'>
                      <span> GRAPHIC DESIGNING</span>
                      <p>Explore the World of Graphics Designing</p>
                    </div>
                  </div>
                  <div>
                    <div className='items'>
                      <img src='/images/service/OFFSETDIGITAL PRINTING1.png' alt="Offset/Digital Printing" />
                    </div>
                    <div className='title'>
                      <span> OFFSET/DIGITAL PRINTING</span>
                      <p>Choosing the Right Printing Method: Offset vs. Digital</p>
                    </div>
                  </div>
                  {/* Repeat similar structure for other carousel items */}
                </Slider>
              </div>
            </div>

            <div className='see-more' data-aos="fade-up">
              <span>
                <button>See More</button>
              </span>
            </div>
          </div>
        </section>


        <section className='section-six'>
          <div className='our-clients'>
            <div className='heading'>
              <h3>Our Clients</h3>
              <span>Meet some of our clients</span>
            </div>
            <div className='clients-carousel'>
              <Slider {...clients}>
                <div><img src="/images/clients/Group 40.png" alt="Slide 1" /></div>
                <div><img src="/images/clients/Group 41.png" alt="Slide 2" /></div>
                <div><img src="/images/clients/Group 42.png" alt="Slide 1" /></div>
                <div><img src="/images/clients/Group 43.png" alt="Slide 2" /></div>
                <div><img src="/images/clients/Group 44.png" alt="Slide 1" /></div>
              </Slider>
            </div>
          </div>
        </section>

        <section className='section-seven'>
          <div className='progres'>
            <div className='row'>
              <div className='col-12 col-xs-12 col-sm-12 col-md-7 col-lg-8 col-xl-8'>
                <div className='content'>
                  <div className='heading' data-aos="fade-right"  data-aos-duration="1000">
                    <h1>Looking for a Printing
                      <br />
                      services in UAE ?
                    </h1>
                    <img src='' />
                  </div>
                  <div className='sub-heading' data-aos="fade-right" data-aos-delay="200"  data-aos-duration="1000">
                    <p>Don't hesitate to reach out to us for assistance or service! Contact us
                      for prompt support. Whether you have an issue or need expert
                      advice, our dedicated team is ready to ensure your needs are met
                      quickly and efficiently. Trust us to provide you with the help and
                      service you deserve, whenever you require it</p>
                  </div>

                  <div className='progres-icons' data-aos="fade-up" data-aos-delay="400">
                    <div className='row'>
                      <div className='col-6' data-aos="zoom-in" data-aos-delay="500"  data-aos-duration="1000">
                        <div className='set'>
                          <img src="/images/progres/Group 43.png" alt="Expertise Icon" />
                          <div className='text'>
                            <h5>Expertise</h5>
                            <span>10 +</span>
                          </div>
                        </div>
                      </div>
                      <div className='col-6' data-aos="zoom-in" data-aos-delay="600"  data-aos-duration="1000">
                        <div className='set'>
                          <img src="/images/progres/Group 41.png" alt="Quality Icon" />
                          <div className='text'>
                            <h5>Quality</h5>
                            <span>100%</span>
                          </div>
                        </div>
                      </div>
                      <div className='col-6' data-aos="zoom-in" data-aos-delay="700"  data-aos-duration="1000">
                        <div className='set'>
                          <img src="/images/progres/Group 44.png" alt="Design Icon" />
                          <div className='text'>
                            <h5>Design</h5>
                            <span>Innovative</span>
                          </div>
                        </div>
                      </div>
                      <div className='col-6' data-aos="zoom-in" data-aos-delay="800"  data-aos-duration="1000">
                        <div className='set'>
                          <img src="/images/progres/Group 42.png" alt="Support Icon" />
                          <div className='text'>
                            <h5>Support</h5>
                            <span>24 hours</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className='col-12 col-xs-12 col-sm-12 col-md-5 col-lg-4 col-xl-4' >
                <div className='progres-image'>
                  <img src='/images/progres/Black location tag.png' data-aos="fade-left" data-aos-delay="300" alt="Location Icon"  data-aos-duration="1000" />
                </div>
              </div>
            </div>


            <div className='progres-count' ref={countRef}>
      <img className='background-img' src='/images/count/EXPRT WORK.webp' alt="Background" />
      <div className="stats-container">
        {counters.map((counter, index) => (
          <div className="stat" key={index}>
            <CountUp endValue={counter.endValue} />
            <p>{counter.label}</p>
          </div>
        ))}
      </div>
    </div>
          </div>
        </section>

        <section className='section-eight' id='contact'>
          <div className='contact-us' data-aos="fade-up">
            <div className='row'>
              <div className='col-12 col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6'>
                <div className='heading' data-aos="fade-right"  data-aos-duration="1000">
                  <h1>Contact Us</h1>
                  <p>Printzed Design & Artwork Solution:
                    Innovating design & printing since 2015, setting the standard worldwide.
                  </p>
                </div>
                <div className='details' data-aos="fade-right" data-aos-delay="200"  data-aos-duration="1000">
                  <img src='/images/contact-us/phone.png' alt="Phone Icon" />
                  <div>
                    <p>Call Us Now</p>
                    <span>+971 52 390 6635 | +971 56 330 3837</span>
                  </div>
                </div>
                <div className='details' data-aos="fade-right" data-aos-delay="300"  data-aos-duration="1000">
                  <img src='/images/contact-us/email.png' alt="Mail Icon" />
                  <div>
                    <p>Mail Us Now</p>
                    <span>sales@printzed.ae</span>
                  </div>
                </div>
              </div>

              <div className="col-12 col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" data-aos="fade-left" data-aos-duration="1000">
      <div className="contact-form">
        <div className="inputs">
          <div className="name">
            <img src="/images/contact-us/contact.png" alt="User Icon" />
            <input 
              placeholder="Your Name" 
              value={name} 
              onChange={(e) => setName(e.target.value)} 
              type='text'
            />
          </div>
          <div className="second">
            <div className="number">
              <img src="/images/contact-us/phone.png" alt="Phone Icon" />
              <input 
                placeholder="Your Number" 
                value={number} 
                onChange={(e) => setNumber(e.target.value)} 
                type='number'
              />
            </div>
            <div className="mail">
              <img src="/images/contact-us/email.png" alt="Email Icon" />
              <input 
                placeholder="Your Email" 
                value={email} 
                onChange={(e) => setEmail(e.target.value)} 
                type='mail'
              />
            </div>
          </div>
          <div className="needs">
            <textarea 
              placeholder="What Type of Service You Need?" 
              value={services} 
              onChange={(e) => setServices(e.target.value)} 
              typeof='text'
            />
          </div>
          <button className="submit-btn" onClick={handleSend}>
            Send
            <img src="/images/contact-us/SEND.png" alt="Send Icon" />
          </button>
        </div>
      </div>
    </div>
            </div>
          </div>
        </section>


        <section className='section-nine'>
          <div className='footer'>
            <div className='row'>
              <div className='col-6 col-sm-6 col-md-4 col-lg-2 col-xl-2'>
                <div className='company-info'>
                  <h4>Comapany Info</h4>
                  <ul>
                    <li>About Us</li>
                    <li>Carrier</li>
                    <li>We are hiring</li>
                    <li>Blog</li>
                  </ul>
                </div>
              </div>
              <div className='col-6 col-sm-6 col-md-4 col-lg-2 col-xl-2 legal-hide'>
                <div className='legal'>
                  <h4>Legal</h4>
                  <ul>
                    <li>About Us</li>
                    <li>Carrier</li>
                    <li>We are hiring</li>
                    <li>Blog</li>
                  </ul>
                </div>
              </div>
              <div className='col-6 col-sm-6 col-md-4 col-lg-2 col-xl-2'>
                <div className='features'>
                  <h4>Features</h4>
                  <ul>
                    <li>Buisness Marketing</li>
                    <li>User Analytic</li>
                    <li>Live Chat</li>
                    <li>Unlimited Support</li>
                  </ul>
                </div>
              </div>
              <div className='col-12 col-sm-12 col-md-4 col-lg-3 col-xl-3'>
                <div className='get-in-touch'>
                  <h4>Get In Touch</h4>
                  <ul>
                    <span><img src='/images/footer/CALL.png'></img><li>+971 52 390 6635|| +971 56 330 3837</li></span>
                    <span><img src='/images/footer/LOCATION.png'></img> <li>Shop No: 13, Sheikha Noora Bldg, Near
                      Canadian Hospital, Abu hail, Dubai - UAE.</li></span>
                    <span><img src='/images/footer/MAIL.png'></img> <li>sales@printzed.ae</li></span>

                  </ul>
                </div>
              </div>
              <div className='col-12 col-sm-12 col-md-4 col-lg-2 col-xl-2'>
                <div className='footer-logo'>
                  <img src='/images/Navbar/LOGO 2.png'></img>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className="copyright">
          <div className='row'>
              <div className='col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 '>
                <div className='c-name'>
                © Printzed all rights reserved | Powerd by Printzed
                </div>
              </div>
              <div className='col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6'>
              <div className="c-icons">
  <a href="https://www.instagram.com/printz_advt/profilecard/?igsh=MWc4NWl4YjVuNWlyOA==" target="_blank" rel="noopener noreferrer">
    <img src="/images/copyright/INSTA.png" alt="Instagram" />
  </a>
  <a href="https://www.facebook.com/profile.php?id=61554371544586&mibextid=kFxxJD" target="_blank" rel="noopener noreferrer">
    <img src="/images/copyright/FB.png" alt="Facebook" />
  </a>
  <a  target="_blank" rel="noopener noreferrer">
    <img src="/images/copyright/x.png" alt="Other Link" />
  </a>
</div>

                </div>
          </div>
        </div>
      </div>

      <div className="whatsapp-icon">
  <a href="https://wa.me/971523906635" target="_blank" rel="noopener noreferrer">
    <i className="bi bi-whatsapp"></i>
  </a>
</div>




      {/* <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Data Code Master<br></br><span>Work Progress</span></h5>
        <button type="button" class="btn-close btn-light" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
      <div class="progress-tracker">
  
  <div class="progress-step completed">
    <div class="icon">🤝</div>
    <p class="step-label">Conformed</p>
    <p class="step-date">Jan 20</p>
  </div>

 
  <div class="connecting-line completed"></div>

  <div class="progress-step completed">
    <div class="icon">🎨</div>
    <p class="step-label">Designing</p>
    <p class="step-date">Jan 22</p>
  </div>

  
  <div class="connecting-line completed"></div>

  <div class="progress-step in-progress">
    <div class="icon">🖨️</div>
    <p class="step-label">Printing</p>
    <p class="step-date">on work</p>
  </div>

  <div class="connecting-line upcoming"></div>


  <div class="progress-step upcoming">
    <div class="icon">🚚</div>
    <p class="step-label">Deliver</p>
    <p class="step-date">waiting..</p>
  </div>

  <div class="connecting-line upcoming"></div>


  <div class="progress-step upcoming">
    <div class="icon">✔️</div>
    <p class="step-label">Successful</p>
    <p class="step-date">work completed</p>
  </div>
</div>




<div class="details-section">
  <h2>Details</h2>
  <div class="details-grid">
    <div class="detail-item"><strong>Company Name:</strong> Data code Masters</div>
    <div class="detail-item"><strong>Client Name:</strong> John Doe</div>
    <div class="detail-item"><strong>Product:</strong> Product XYZ</div>
    <div class="detail-item"><strong>Quantity:</strong> 100 units</div>
    <div class="detail-item"><strong>Price:</strong> $1000</div>
    <div class="detail-item"><strong>Track ID:</strong> ABC123</div>
    <div class="detail-item"><strong>Address:</strong> 123 Main St, Cityville</div>
  </div>
</div>

      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
       
      </div>
    </div>
  </div>
</div> */}
    </div>
  )
}

export default Home