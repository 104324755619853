import logo from './logo.svg';
import './App.css';
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from './components/Home/Home'
import Login from './components/Login/Login';
import Pdf from './components/Pdf/Pdf';
import ManageStaff from './components/ManageStaff/ManageStaff';
import Admin from './components/Admin/Admin';
import Designer from './components/Designer/Designer';
import Production from './components/Production/Production';
import Packing from './components/Packing/Packing';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/login-staff" element={<Login />} />
          <Route path="/main-admin" element={<ManageStaff />} />
          <Route path="/sales" element={<Pdf />} />
          <Route path="/admin" element={<Admin />} />
          <Route path='/designer' element={<Designer />} />
          <Route path='/production' element={<Production />} />
          <Route path='/packing' element={<Packing />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
