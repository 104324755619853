import React from 'react'
import axios from 'axios';
import { useState, useEffect } from 'react';
import './Admin.css'
import { Navigate, useNavigate } from 'react-router-dom';

function Admin() {
  const [staffList, setStaffList] = useState([]);
  const [designerList, setdesignerList] = useState([])
  const [selectedStaff, setSelectedStaff] = useState('');
  const [selectedDesigner, setSelectedDesigner] = useState('')
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [accessToken, setAccessToken] = useState('');
  const [productList, setProductList] = useState([]);
  const [selectedproduct, setSelectedproduct] = useState('')
  const [workMappedList, setworkMappedList] = useState([])
  const [mappedDesigners, setMappedDesigners] = useState([]);
  const [designer_id , setdesigner_id] =useState('')
  const[product_id,setproduct_id] = useState('')
    const [trackid,settrackid] = useState('')

  const navigate = useNavigate()

  const logout =()=>{
    localStorage.clear()
    navigate('/login-staff')
  }

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      setAccessToken(token);
      fetchStaffList(token);
      fetchDesignerList(token)
      fetchProductList(token)
      workAssignList(token)
    } else {
      navigate('/login-staff')
    }
  }, []);


 
  const fetchMappedDesigners = async (token) => {
    try {
      const response = await axios.get('http://localhost:8000/mapped-designers/', {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });
      setMappedDesigners(response.data);
      alert('this data', response.data)
    } catch (error) {
      console.log(error, 'Failed to fetch mapped designers.');
    }
  };

  // Call this function to delete a mapped work assignment
  const handleDelete = async (designerId) => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.delete(`http://localhost:8000/delete-mapped-work/${designerId}/`, {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });



      alert('Work assignment deleted successfully.');
      workAssignList(accessToken)
    } catch (error) {
      console.log(error, 'Failed to delete work assignment.');
      alert('Failed to delete work assignment.');
    }
  };


  const fetchStaffList = async (token) => {
    try {
      const response = await axios.get('http://localhost:8000/list-staff/', {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });
      setStaffList(response.data);
    } catch (error) {
      console.log(error, 'Failed to fetch staff list.');
    }
  };

  const fetchProductList = async (token) => {
    try {
      const response = await axios.get('http://localhost:8000/get_product_list/', {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });
      setProductList(response.data);
    } catch (error) {
      console.log(error, 'Failed to fetch staff list.');
    }
  };

  const fetchDesignerList = async (token) => {
    try {
      const response = await axios.get('http://localhost:8000/designer-list/', {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });
      setdesignerList(response.data);
    } catch (error) {
      console.log(error, 'Failed to fetch staff list.');
    }
  };

  const handleMapDesignation = async () => {
    if (!selectedStaff) {
      setError('Please select a staff and designation.');
      return;
    }

    try {
      const response = await axios.post(
        'http://localhost:8000/map-designation/',
        { uuid: selectedStaff, designation: 'designer' },
        { headers: { 'Authorization': `Bearer ${accessToken}` } }
      );
      setSuccess('Designation mapped successfully.');
      setSelectedStaff('');


    } catch (error) {
      console.log(error, 'Failed to map designation.');
    }
  };



  const getProductDetails=(e)=>{
    console.log()
    setproduct_id(e?.product_id) // Use selectedDesigner.uuid for the designer ID
     settrackid(e?.sales_orders?.[0]?.track_id)
  }



  const handleMapwork = async () => {



          // Ensure the designer and track ID are available
          // Ensure track_id is accessible
          

    // if (!designer_id || !trackid || !selectedproduct) {
    //   setError('Please select a designer, product, and track ID.');
    //   return;
    // }
  
    try {
      // Post request to map the work
      const response = await axios.post(
        'http://localhost:8000/map-work/',
        {designer_uuid:designer_id, product_id: product_id, track_id: trackid }, // Use designer_id and trackid
        { headers: { 'Authorization': `Bearer ${accessToken}` } }
      );
  
      // Set success message and reset fields
      setSuccess('Work mapped successfully.');
      setSelectedStaff('');
      workAssignList(accessToken);
    } catch (error) {
      console.error('Failed to map work:', error);
      setError('Failed to map work.');
    }
  };
  

  const workAssignList = async (token) => {
    try {
      const response = await axios.get('http://localhost:8000/mapped-work-list/', {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });
      setworkMappedList(response.data);
    } catch (error) {
      // alert(error, 'Failed to fetch staff list.');
    }
  };

  return (
    <div>
      <div className="manage-staff">
        <h4>Manage Designer <i class="bi bi-box-arrow-right" onClick={logout}></i></h4>
      </div>
      <div className="add-staff">
        <div className="row">
          <div className="col-6">
            <div className="assign-staff">
              <div className="row">
                <div className="col-6">
                  <select onChange={(e) => setSelectedStaff(e.target.value)}>
                    <option value="">Select Staff</option>
                    {staffList.map((staff,index) => (
                      <option key={index} value={staff.uuid}>
                        {staff.username}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-6">
                  <div className="save-designer">
                    <button onClick={handleMapDesignation}>Map Designation</button>
                  </div>
                </div>
              </div>

            </div>
          </div>
          <div className="col-6">
            <div className="add-user">
              <div className='row'>
                <div className='col-6'>

<select onChange={(e) => getProductDetails(JSON.parse(e.target.value))}>
  <option value="">Select Work</option>
  {productList.map((item, index) => (
    <option value={JSON.stringify(item)} key={index}>
      {item.product_name} ({item.company_name})
    </option>
  ))}
</select>

                </div>
                <div className='col-6'>
                  <select onChange={(e) => setdesigner_id(e.target.value)}>
                    <option value="">Select Designer</option>
                    {designerList.map((staff,index) => (
                      <option key={index} value={staff.uuid}>
                        {staff.username}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <button onClick={handleMapwork} className="mt-3">
                Map Work
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="user-designation-container">
  <h3>Mapped Designers</h3>
  <div className="table-responsive">
    <table className="position-table">
      <thead>
        <tr>
          <th>Designer Name</th>
          <th>Assigned Work</th>
          <th>Company Name</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
        {workMappedList.map((designer, index) => (
          <tr key={index}>
            <td>{designer.designer_name}</td>
            <td>{designer.product_name}</td>
            <td>{designer.company_name}</td>
            <td>
              <i
                onClick={() => handleDelete(designer.id)}
                className="bi bi-trash3"
              ></i>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
</div>

    </div>
  )
}

export default Admin