import React, { useEffect , useState, useRef } from 'react';
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import './Pdf.css'
import { useNavigate } from 'react-router-dom';


const Pdf = () => {
  const [formData, setFormData] = useState({
    companyName: '',
    clientName: '',
    phoneNumber: '',
    trackId: '',
    address: '',
    deliveryDate: '',
    totalBalance: '',
    salesPerson: '',
  });

  const [products, setProducts] = useState([{ productName: '', quantity: '', price: '' }]);
  const formRef = useRef(); // Reference to the form container

  const navigate = useNavigate()


  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      // navigate('/sales')
    } else {
      navigate('/login-staff')
    }
  }, []);

const logout =()=>{
  localStorage.clear()
  navigate('/login-staff')
}
  const handleFormChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleProductChange = (index, e) => {
    const { name, value } = e.target;
    const updatedProducts = [...products];
    updatedProducts[index][name] = value;
    setProducts(updatedProducts);
  };

  const addProductRow = () => {
    setProducts([...products, { productName: '', quantity: '', price: '' }]);
  };

  const removeProductRow = (index) => {
    const updatedProducts = products.filter((_, i) => i !== index);
    setProducts(updatedProducts);
  };

  const generatePDF = async () => {
    const formElement = formRef.current;

    html2canvas(formElement).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF('p', 'mm', 'a4');
      const imgWidth = 190;
      const pageHeight = pdf.internal.pageSize.height;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      let heightLeft = imgHeight;
      let position = 0;

      pdf.addImage(imgData, 'PNG', 10, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;

      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        pdf.addPage();
        pdf.addImage(imgData, 'PNG', 10, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
      }

      pdf.save("form.pdf");
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Prepare the form data including products and other fields
    const orderData = {
      trackId: formData.trackId,
      companyName: formData.companyName,
      clientName: formData.clientName,
      phoneNumber: formData.phoneNumber,
      address: formData.address,
      deliveryDate: formData.deliveryDate,
      totalBalance: formData.totalBalance,
      salesPerson: formData.salesPerson,
      products: products, // Include products in the request
    };

    try {
      // Send order data to the backend to save it in the database
      const response = await fetch('http://localhost:8000/create-order/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(orderData),
      });

      if (response.ok) {
        const data = await response.json();
        console.log('Order created successfully:', data);

        // Generate PDF after successfully submitting the form
        generatePDF();
      } else {
        const errorData = await response.json();
        console.error('Error creating order:', errorData);
      }
    } catch (error) {
      console.error('Error during submission:', error);
    }
  };

  return (
    
    <div >
              <div className="manage-staff">
          <h4>Sales <i class="bi bi-box-arrow-right" onClick={logout}></i></h4>
          
        </div>
      <div className="form-container">
        <form onSubmit={handleSubmit} className="form">
          {/* Input Form Section */}
          <div className="row allrow">
            <div className="col-12 col-sm-6 col-md-4">
              <label>Company Name</label>
              <input
                type="text"
                name="companyName"
                value={formData.companyName}
                onChange={handleFormChange}
                placeholder="Enter company name"
              />
            </div>
            <div className="col-12 col-sm-6 col-md-4">
              <label>Client Name</label>
              <input
                type="text"
                name="clientName"
                value={formData.clientName}
                onChange={handleFormChange}
                placeholder="Enter client name"
              />
            </div>
            <div className="col-12 col-sm-6 col-md-4">
              <label>Phone Number</label>
              <input
                type="text"
                name="phoneNumber"
                value={formData.phoneNumber}
                onChange={handleFormChange}
                placeholder="Enter phone number"
              />
            </div>
          </div>

          <label>Products</label>
          {products.map((product, index) => (
            <div className="row allrow" key={index}>
              <div className="col-12 col-sm-6 col-md-3">
                <input
                  type="text"
                  name="productName"
                  value={product.productName}
                  onChange={(e) => handleProductChange(index, e)}
                  placeholder="Product Name"
                />
              </div>
              <div className="col-12 col-sm-6 col-md-3">
                <input
                  type="number"
                  name="quantity"
                  value={product.quantity}
                  onChange={(e) => handleProductChange(index, e)}
                  placeholder="Quantity"
                />
              </div>
              <div className="col-12 col-sm-6 col-md-3">
                <input
                  type="number"
                  name="price"
                  value={product.price}
                  onChange={(e) => handleProductChange(index, e)}
                  placeholder="Price"
                />
              </div>
              <div className="col-12 col-sm-6 col-md-3">
                <button type="button" onClick={addProductRow} className="btn-add">+</button>
                {products.length > 1 && (
                  <button type="button" onClick={() => removeProductRow(index)} className="btn-remove">-</button>
                )}
              </div>
            </div>
          ))}
          <div className="row allrow">
            <div className="col-12 col-sm-12 col-md-12 col-lg-12">
              <label>Total Balance</label>
              <input
                type="number"
                name="totalBalance"
                value={formData.totalBalance || ''}
                onChange={handleFormChange}
                placeholder="Enter total balance"
              />
            </div>
            <div className="col-12 col-sm-12 col-md-12 col-lg-12">
              <label>Sales Person</label>
              <input
                type="text"
                name="salesPerson"
                value={formData.salesPerson || ''}
                onChange={handleFormChange}
                placeholder="Enter Salesperson name"
              />
            </div>
          </div>

          <label>Track ID</label>
          <input
            type="text"
            name="trackId"
            value={formData.trackId}
            onChange={handleFormChange}
            placeholder="Enter Track ID"
          />

          <label>Address Details</label>
          <textarea
            name="address"
            value={formData.address}
            onChange={handleFormChange}
            placeholder="Enter address details"
          />

          <label>Approximate Delivery Date</label>
          <input
            type="date"
            name="deliveryDate"
            value={formData.deliveryDate}
            onChange={handleFormChange}
          />

          <button type="submit" className="submit-btn">Generate PDF</button>
        </form>
      </div>

      <div className='pdf-temp' ref={formRef} style={{ position: 'relative', padding: '20px', backgroundColor: '#fff', marginTop: '20px', height: '100%' }}>
        {/* Background Image */}
        <img
          src='/images/footer/LOGO 1.png'
          alt="Background"
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            width: '80%',
            height: 'auto',
            opacity: 0.1,
            transform: 'translate(-50%, -50%)',
            zIndex: 0,
          }}
        />

        {/* Content Section */}
        <div style={{ position: 'relative', zIndex: 1 }}>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div>
              <div style={{ fontWeight: 'bold', fontSize: '28px', marginBottom: '4px' }}>Printzed</div>
              <p style={{ fontSize: '16px', margin: '2px 0 5px 0px' }}>Date: {new Date().toLocaleDateString()}</p>
            </div>
            <img style={{ width: '180px', objectFit: 'contain' }} src='/images/footer/LOGO 1.png' alt="Logo" />
          </div>
          
          <hr />
          <p style={{ fontSize: '23px', fontWeight: '500', margin: '0px 0px 26px 0px' }}>{formData.companyName}</p>
          <p style={{ fontSize: '20px', margin: '0px 0px 10px 0px' }}>Track ID &nbsp; : &nbsp; {formData.trackId}</p>
          <p style={{ fontSize: '20px', margin: '0px 0px 10px 0px' }}>Delivery Date &nbsp; : &nbsp; {formData.deliveryDate}</p>
          <p style={{ fontSize: '20px', margin: '0px 0px 10px 0px' }}>Salesperson  &nbsp;: &nbsp; {formData.salesPerson}</p>
          
          <h5 style={{ fontSize: '20px', marginTop: '20px', marginBottom: '10px', fontWeight: 'bold' }}>Product Details:</h5>
          <table style={{ width: '100%', borderCollapse: 'collapse' }}>
            <thead>
              <tr>
                <th style={{ border: '1px solid #ddd', padding: '8px', backgroundColor: '#f0f0f0', fontSize: '20px' }}>Item No.</th>
                <th style={{ border: '1px solid #ddd', padding: '8px', backgroundColor: '#f0f0f0', fontSize: '20px' }}>Description</th>
                <th style={{ border: '1px solid #ddd', padding: '8px', backgroundColor: '#f0f0f0', fontSize: '20px' }}>Quantity</th>
              </tr>
            </thead>
            <tbody>
              {products.map((product, index) => (
                <tr key={index}>
                  <td style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'center', fontSize: '20px' }}>{index + 1}</td>
                  <td style={{ border: '1px solid #ddd', padding: '8px', fontSize: '20px' }}>{product.productName}</td>
                  <td style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'center', fontSize: '20px' }}>{product.quantity}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Pdf;
