import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

function Designer() {
  const [SelectWork, setSelectWork] = useState("");
  const [workList, setworkList] = useState([]);
  const [selectedWorkDetails, setSelectedWorkDetails] = useState(null); // State to store selected work details
  const [UpdateStatus, setUpdateStatus] = useState("");
  const [AccessToken, setAccessToken] = useState("");

  const navigate = useNavigate()

  const logout =()=>{
    localStorage.clear()
    navigate('/login-staff')
  }

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      setAccessToken(token);
      fetchWorkList(token);
    } else {
      navigate('/login-staff')
    }
  }, []);

  const fetchWorkList = async (token) => {
    try {
      const response = await axios.get('http://localhost:8000/get-mapped-work/', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setworkList(response.data);
    } catch (error) {
      console.log(error, 'Failed to fetch work list.');
    }
  };

  const handleWorkSelection = (e) => {
    const selectedWorkId = e.target.value;
    setSelectWork(selectedWorkId);

    // Find and set details of the selected work
    const selectedWork = workList.find((work) => work.id.toString() === selectedWorkId);
    setSelectedWorkDetails(selectedWork || null);
  };

  const updateWork = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.patch(
        `http://localhost:8000/update-work-status/${SelectWork}/`,
        { status: UpdateStatus },
        {
          headers: {
            Authorization: `Bearer ${AccessToken}`,
          },
        }
      );
      alert(response.message || "Status updated successfully!");
    } catch (err) {
      alert(err.response.data.error || "Failed to update status.");
    }
  };

  return (
    <div>
      <div className="manage-staff">
        <h4>Designer <i class="bi bi-box-arrow-right" onClick={logout}></i></h4>
      </div>
      <div className="add-staff">
        <div className="row">
          <div className="col-6">
            <div className="assign-staff">
              <div className="row">
                <div className="col-4">
                  <select onChange={handleWorkSelection}>
                    <option value="">Select Work</option>
                    {workList.map((work, index) => (
                      <option key={index} value={work.id}>
                        {work.product_name} | ({work.company_name})
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-4">
                  <select onChange={(e) => setUpdateStatus(e.target.value)}>
                    <option value="">Update Status</option>
                    <option value="working on it">Working On it</option>
                    <option value="forward to print">Forward to Print</option>
                  </select>
                </div>
                <div className="col-4">
                  <div className="save-designer">
                    <button onClick={updateWork}>Update Work</button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <br></br>
          <br></br>
          <div className="">
            {selectedWorkDetails && (
              <div className="work-details">
                <h5>Work Details</h5>
                <br></br>
                <p><strong>Designer Name:</strong> {selectedWorkDetails.designer_name}</p>
                <p><strong>Product Name:</strong> {selectedWorkDetails.product_name}</p>
                <p><strong>Company Name:</strong> {selectedWorkDetails.company_name}</p>
                <p><strong>Created At:</strong> {new Date(selectedWorkDetails.created_at).toLocaleString()}</p>
                <p><strong>Assigned At:</strong> {new Date(selectedWorkDetails.assigned_at).toLocaleString()}</p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Designer;
